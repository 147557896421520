import { Options, Vue } from "vue-class-component";
import { IEmergencyContact } from "@/types/EmergencyContact";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import draggable from "vuedraggable";
import { notify } from "@/services/helpers";

@Options({
  name: "EmergencyContact",
  components: {
    Breadcrumb,
    ConfirmationModal,
    PaginationComponent,
    draggable,
  },
  props: {},
  computed: {
    dragOptions() {
      return {
        animation: 400,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
})
export default class EmergencyContact extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  emergencyContacts: IEmergencyContact[] = [];

  enabled = true;
  drag = false;
  loading = false;

  page = 0;
  perPage = 10;
  totalCount = 0;
  crumbs: any = ["Dashboard", "Emergency Contact"];
  // Init Modal
  $refs!: {
    confirmDialogue: HTMLFormElement;
  };
  $router: any;

  async checkMove(e: any) {
    const list = this.emergencyContacts.map((item, index) => {
      return {
        id: item.id,
        priority: index + 1,
      };
    });

    this.loading = true;
    await this.workForceService
      .post(`/EmergencyContact/updatePriority`, list)
      .then((response: any) => {
        this.loading = false;
        if (response.isSuccess) {
          notify(
            "Contacts priority updated successfully.",
            "Success",
            "success"
          );
        } else {
          notify(
            "Oops! Something went wrong, try again later",
            "Error",
            "danger"
          );
        }
      });
  }

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  goToPage(page: number) {
    return this.workForceService
      .get<IEmergencyContact[]>(
        `/emergencycontact?PageIndex=${page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        return (this.emergencyContacts = response.content.items);
      });
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  async initialize() {
    await this.workForceService
      .get<IEmergencyContact[]>(
        `/emergencycontact?PageIndex=${this.page}&PageSize=${this.perPage}`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.emergencyContacts = response.content.items;
      });
  }

  async created() {
    await this.initialize();
  }

  emergencyContactId = 0;
  async deleteEmergencyContact(id: number) {
    this.emergencyContactId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Delete Contact?",
      message:
        "Are you sure you want to delete this Emergency Contact? It cannot be undone.",
      okButton: "Delete",
      cancelButton: "Cancel",
      theme: "danger",
      type: "confirmation",
    });
    if (modal) {
      await this.okClick();
      this.emergencyContactId = 0;
    } else {
      this.emergencyContactId = 0;
      return false;
    }
  }

  okClick() {
    this.workForceService
      .delete(`/emergencycontact/${this.emergencyContactId}`)
      .then((response: any) => {
        this.initialize();
        if (!response.isError) {
          notify("Contact deleted successfully.", "Success", "success");
        } else {
          notify(response.errors, "Error", "danger");
        }
      });
  }
}
