<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs"> </Breadcrumb>

  <!--  Content-->
  <div class="custom-container">
    <page-title>
      <template #title>
        <span class="h4 text-primary">Emergency Contacts</span>
      </template>

      <template #button>
        <div class="">
          <button
            class="btn btn-primary"
            @click="goToRoute('/admin/emergencycontact/new')"
          >
            <font-awesome-icon class="mr-1" icon="plus-circle" />
            Add new
          </button>
        </div>
      </template>
    </page-title>

    <!--      Page Body-->

    <div class="row pt-2">
      <div class="col-12">
        <div class="overlay" v-if="loading">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <template v-if="emergencyContacts.length > 0">
          <draggable
            :class="loading ? 'card-loading' : ''"
            v-model="emergencyContacts"
            :disabled="!enabled"
            v-bind="dragOptions"
            @update="checkMove"
            @start="drag = true"
            @end="drag = false"
            item-key="id"
          >
            <template #item="{ element, index }">
              <card-row style="cursor: move">
                <!--        Details-->
                <template v-slot:details>
                  <div class="font-weight-bold">
                    {{ element.firstName }} {{ element.lastName }}
                    <span
                      class="badge badge-primary-light m-0 ml-2 px-1 py-0 text-primary-dark"
                      style="font-size: 18px; opacity: 0.7"
                      >#{{ index + 1 }}</span
                    >
                  </div>
                  <div class="text-muted">
                    {{ "Relationship: " + element.relationship }}
                  </div>
                  <div class="text-muted">
                    {{ "Mobile Number: " + element.mobilePhone }}
                  </div>
                </template>

                <!--        Actions-->
                <template v-slot:actions>
                  <!--                    edit-->
                  <router-link
                    class="btn btn-sm btn-primary px-2"
                    :to="{
                      name: 'Edit Emergency Contact',
                      path: '/admin/emergencycontact/edit',
                      params: { emergencyContactId: element.id },
                    }"
                  >
                    <font-awesome-icon class="mr-1" icon="pencil-alt" />
                    Edit
                  </router-link>
                  <!--                    Cancel-->
                  <button
                    class="btn btn-sm btn-danger ml-1"
                    @click.prevent="deleteEmergencyContact(element.id)"
                  >
                    <font-awesome-icon class="mr-1" icon="trash" />
                    Delete
                  </button>
                </template>
              </card-row>
            </template>
          </draggable>
        </template>
        <template v-else>
          <div class="card mb-3 shadow-sm">
            <div class="card-body border-0 rounded py-3">
              <span class="h6">No Data Available</span>
            </div>
          </div>
        </template>
      </div>

      <!--        Pagination-->
      <PaginationComponent
        v-if="totalCount > 15"
        :page-count="totalPages"
        :perPage="perPage"
        :click-handler="goToPage"
      ></PaginationComponent>
    </div>
  </div>

  <!--  Modal-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./emergency-contact.ts" />

<style scoped></style>
